import logo from './logo.svg';
//import './App.css';
import { Routes, Route, Outlet, Link } from "react-router-dom";
import {Footer,Header,Layout as Lx} from './layout/elements';
import {CV,Skills,Profile_head} from './pages/cv';
import {Projects,Project,ProjectFrame} from './pages/projects';
import { Publications } from './pages/publications';

function App() {
  return (
    <div>

<div className="mx-auto max-w-3xl text-base leading-7 text-gray-200">


</div>

     <Lx>
      <Routes>

          <Route index element={<CV />} />
          <Route path="cv" element={<CV />} />
          <Route path="publications" element={<Publications />} />
          <Route path="work" element={<ProjectFrame />}>
            <Route index element={<Projects/>}/>
            <Route path=":projecthandle" element={<Project />}></Route>
          </Route>

          {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
          <Route path="*" element={<NoMatch />} />          



      </Routes>     
        </Lx>     

    </div>
  );
}
function Layout() {
  return <div>I can hear you!</div>
}

function NoMatch() {
  return <div>404</div>
}

export default App;
