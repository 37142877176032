import logo_nodejs from './../logo/icons8-nodejs.svg';
import logo_python from './../logo/icons8-python.svg';
import logo_react from './../logo/icons8-react-native.svg';
import logo_excel from './../logo/icons8-microsoft-excel.svg';
import logo_mysql from './../logo/icons8-mysql-logo.svg';
import logo_php from './../logo/icons8-php-48.png';
import logo_javascript from './../logo/icons8-javascript.svg';
import logo_photoshop from './../logo/icons8-adobe-photoshop.svg';
import logo_canada from './../logo/icons8-canada-48.png';
import logo_eu from './../logo/icons8-flag-of-europe-48.png';

import logo_udacity from './../logo/udacity.png';
import logo_quantic from './../logo/quantic.png';
import logo_cfi from './../logo/cfi.jpeg';
import logo_bielefeld from './../logo/uni-bielefeld.png';
import logo_placeholder from './../logo/diamond.png';
import logo_hfc from './../logo/hfc.png';
import logo_gatech from './../logo/gatech.png';
import logo_draperuniversity from './../logo/draperuniversity.png';
import logo_goskilling from './../logo/goskilling.png';

import { Centrify } from '../layout/elements';
const general = [
  {
    id: 1,
    text: 'In Express Entry Pool',
    logo: logo_canada,
  },
  {
    id: 1,
    text: 'EU Work Authorization',
    logo: logo_eu,
  }
]
export function WorkAuthorization() {
  return <Centrify>
<div className="mx-auto mt-8 grid max-w-2xl grid-cols-2 gap-4 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:gap-8">
    {general.map((card) => (
      <div key={card.name} className="flex gap-x-4 rounded-xl bg-white/5 p-6 ring-1 ring-inset ring-white/10">
        <img className="h-8 w-8 rounded-full" src={card.logo} alt="" />
        <div className="text-base leading-7">
          <h3 className="font-semibold text-gray-400">{card.text}</h3>
        </div>
      </div>
    ))}
  </div>    
    </Centrify>
}

export function Activities() {
  return <Centrify>
    <h1 className="truncate text-base font-medium text-gray-900 mt-10">Activities</h1>
    <div className="text-gray-800 text-center mx-auto mt-4  gap-6 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:gap-8">
      Gaming (strategy, simulation, sports) • Baseball • Music (country, jazz) • Writing • Cycling and hiking • Cooking
    </div>    
  </Centrify>

}

function CVEntry({handle,logo,entity,period,position,grade,preview}) {
  return <li key={handle} className="py-4">
  <div className="flex space-x-3">
    <img className="h-6 w-6 rounded-full" src={logo} alt="" />
    <div className="flex-1 ">
      <div className="flex items-center justify-between">
        <h3 className="text-sm text-gray-600 font-medium">{entity}</h3>
        <p className="text-sm text-gray-600">{period}</p>
      </div>
      <p className="text-gray-800 text-lg font-semibold decoration-4">
      {position}  {grade}
      </p>
      <p className="text-sm text-gray-600">
      {preview}
      </p>
    </div>
  </div>
</li>
}

const jobs = [
  {
    id: 1,
    position: 'Founder, Full-Stack Developer, Product Manager',
    logo: logo_goskilling,
    entity: 'GoSkilling',
    period: '2017 - present',
    grade: '',
    datetime: '2021-01-27T16:35',
    preview:
      'Fair and sustainable recruiting based on your skills rather than presentation skills..',
  },
  {
    id: 2,
    position: 'Back-End Developer',
    logo: logo_placeholder,
    entity: 'OneLiner',
    period: '2019',
    grade: '',
    datetime: '2021-01-27T16:35',
    preview:
      'Working for a Bolder-based startup trying to penetrate the market with a text-based dating app.',
  },
  {
    id: 3,
    position: 'Graphics Designer',
    logo: logo_placeholder,
    entity: 'Freelancer',
    period: '2017 - present',
    grade: '',
    datetime: '2021-01-27T16:35',
    preview:
      'Graphics designer working together with a large e-commerce company from the United States. Licensing partnership with NASA, Rust, and Python Software Foundation. Designs are printed on apparel, household goods and sold in the United States, Europe, and Japan.',
  },
  {
    id: 4,
    position: 'Chief Executive Officer, Product Manager',
    logo: logo_placeholder,
    entity: 'Little Rock Online',
    period: '2012 - 2020',
    grade: '',
    datetime: '2021-01-27T16:35',
    preview:
      'My first business, initially started in Ireland, then moved to Germany to realize Red Moose.',
  },
]


const education = [
  {
    id: 1,
    position: 'Master of Analytics',
    logo: logo_gatech,
    entity: 'Georgia Institute of Technology',
    period: '2024',
    grade: '',
    datetime: '2021-01-27T16:35',
    preview:
      'A new challenge and the chance to learn more to improve myself and boost my venture.',
  },
  {
    id: 2,
    position: 'Master of Business Administration (Executive)',
    logo: logo_quantic,
    entity: 'Quantic School of Business and Technology',
    period: '2021 - 2022',
    grade: 'GPA: 4.0',
    datetime: '2021-01-27T16:35',
    preview:
      'Completed with a high grade despite the passing of my father and caring for my mom sick with cancer. Helped my co-students by preparing and sharing notes - a thousand pages - on every course. Focus on entrepreneurship, blockchain, English business law.',
  },
  {
    id: 3,
    position: 'Fundamentals of Entrepreneurship',
    logo: logo_draperuniversity,
    entity: 'Draper University',
    period: '2020 - 2020',
    grade: '',
    datetime: '2021-01-27T16:35',
    preview:
      'Fantastic experience with the class of June 2020 and team "Magic".',
  },
  {
    id: 4,
    position: 'Social Sciences, Sociology (BA)',
    logo: logo_bielefeld,
    entity: 'Bielefeld University',
    period: '2009 - 2014',
    grade: 'GPA: 2.7',
    datetime: '2021-01-27T16:35',
    preview:
      'Focus on labor sociology. Bachelor thesis on Schimank\'s actor theory and the US-subprime crisis. Additionally, completed two courses on U.S: Criminal Law.',
  },
]

const certificates = [
  {
    id: 1,
    position: 'Commercial Banking & Credit Analyst (CBCA)',
    logo: logo_cfi,
    entity: 'Corporate Finance Institute',
    period: '2023',
    grade: '',
    datetime: '2021-01-27T16:35',
    preview:
      '',
  },
  {
    id: 2,
    position: 'Commercial Markets & Securities Analyst (CMSA)',
    logo: logo_cfi,
    entity: 'Corporate Finance Institute',
    period: '2023',
    grade: '',
    datetime: '2021-01-27T16:35',
    preview:
      '',
  },
  {
    id: 3,
    position: 'Financial Modeling & Valuation Analyst (FMVA)',
    logo: logo_cfi,
    entity: 'Corporate Finance Institute',
    period: '2019',
    grade: '',
    datetime: '2021-01-27T16:35',
    preview:
      '',
  },
  {
    id: 4,
    position: 'Product Manager (Nanodegree)',
    logo: logo_udacity,
    entity: 'Udacity',
    period: '2023',
    grade: '',
    datetime: '2021-01-27T16:35',
    preview:
      '',
  },
  {
    id: 5,
    position: 'AI Product Manager (Nanodegree)',
    logo: logo_udacity,
    entity: 'Udacity',
    period: '2023',
    grade: '',
    datetime: '2021-01-27T16:35',
    preview:
      '',
  },
  {
    id: 6,
    position: 'Predictive Business Analytics (Nanodegree)',
    logo: logo_udacity,
    entity: 'Udacity',
    period: '2019',
    grade: '',
    datetime: '2021-01-27T16:35',
    preview:
      '',
  },
  {
    id: 7,
    position: 'Business Analytics (Nanodegree)',
    logo: logo_udacity,
    entity: 'Udacity',
    period: '2019',
    grade: '',
    datetime: '2021-01-27T16:35',
    preview:
      '',
  },
]

const volunteering = [
  {
    id: 1,
    position: 'IT Coordinator',
    logo: logo_hfc,
    entity: 'Heart for Cameroon',
    period: '2017 - 2022',
    grade: '',
    datetime: '2021-01-27T16:35',
    preview:
      'Responsible for the website. Using Wordpress, later React. ',
  },

]

export function CV() {
  return <div className='px-2 sm:px-0'>
    <WorkAuthorization/>
    <Experience/>
    <Skills/>
    <Activities/>
  </div>
}

export function Experience() {
  return <><Centrify>
    <ul role="list" className="divide-y divide-gray-100">
      <h1 className="truncate text-base font-medium text-gray-900">Work</h1>
      <div className='my-5'>
      {jobs.map((job) => (

        <CVEntry handle={job.id} logo={job.logo} entity={job.entity} period={job.period} position={job.position} grade={job.grade} preview={job.preview}/>
      ))}        
      </div>

      <h1 className="truncate text-base font-medium text-gray-900">Education</h1>
      <div className='my-5'>
      {education.map((school) => (
        <CVEntry handle={school.id} logo={school.logo} entity={school.entity} period={school.period} position={school.position} grade={school.grade} preview={school.preview}/>
      ))}        
      </div>

      <h1 className="truncate text-base font-medium text-gray-900">Certificates</h1>
      <div className='my-5'>
      {certificates.map((certificate) => (
        <CVEntry handle={certificate.id} logo={certificate.logo} entity={certificate.entity} period={certificate.period} position={certificate.position} grade={certificate.grade} preview={certificate.preview}/>
      ))}        
      </div>

      <h1 className="truncate text-sm font-medium text-gray-900">Volunteer</h1>
      <div className='my-5'>
      {volunteering.map((volunteer) => (
        <CVEntry handle={volunteer.id} logo={volunteer.logo} entity={volunteer.entity} period={volunteer.period} position={volunteer.position} grade={volunteer.grade} preview={volunteer.preview}/>
      ))}        
      </div>
    </ul>
  </Centrify>

  </>
}

const skills = [
  {
    name: 'NodeKS',
    handle: 'leonardkrasner',
    imageUrl: logo_nodejs,
  },
  {
    name: 'ReactJS',
    handle: 'floydmiles',
    imageUrl: logo_react,
  },
  {
    name: 'MySQL',
    handle: 'emilyselman',
    imageUrl: logo_mysql,
  },
  {
    name: 'MS Excel',
    handle: 'kristinwatson',
    imageUrl: logo_excel,
  },
  {
    name: 'Python',
    handle: 'kristinwatson',
    imageUrl: logo_python,
  },
  {
    name: 'PHP',
    handle: 'kristinwatson',
    imageUrl: logo_php,
  },
  {
    name: 'JavaScript',
    handle: 'kristinwatson',
    imageUrl: logo_javascript,
  },
  {
    name: 'Adobe Photoshop',
    handle: 'kristinwatson',
    imageUrl: logo_photoshop,
  },
]

export function Skills() {
  return (
    <Centrify>
      <h1 className="truncate text-sm font-medium text-gray-900">Skills</h1>
      <div className="mt-6 flow-root">
        <ul role="list" className="-my-5 divide-y divide-gray-200">
          {skills.map((skill) => (
            <li key={skill.handle} className="py-4">
              <div className="flex items-center space-x-4">
                <div className="flex-shrink-0">
                  <img className="h-8 w-8 rounded-full" src={skill.imageUrl} alt="" />
                </div>
                <div className="min-w-0 flex-1">
                  <p className="truncate text-sm font-medium text-gray-900">{skill.name}</p>
                  {/* <p className="truncate text-sm text-gray-500">{'@' + skill.handle}</p> */}
                </div>
                <div>
                  {/* <a
                    href="#"
                    className="inline-flex items-center rounded-full border border-gray-300 bg-white px-2.5 py-0.5 text-sm font-medium leading-5 text-gray-700 shadow-sm hover:bg-gray-50"
                  >
                    View
                  </a> */}
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </Centrify>)
}