import { useParams, Link, Outlet } from "react-router-dom";
import { Centrify } from "../layout/elements";
import logo_opininio from './../logo/opininio.png';

const projects = [
  [{
    id: 10,
    name: "Opininio",
    handle: "opininio",
    imageUrl: logo_opininio,
    date: "2023 - present",
    url: "opininio.com/",
    category: "Product Management, Full-Stack Developer",
    description: "Opininio is a survey panel where visitors were shown a pair of logos and they pick their preferred brand or product. Applying ELO techniques from chess, this reflects the relative strength of a brand."
  },
    {
    id: 9,
    name: "GoSkilling",
    handle: "goskilling",
    imageUrl: "https://bluecrunch.github.io/images/square0.jpg",
    date: "2019 - present",
    url: "goskilling.com",
    category: "Product Management, Full-Stack Developer",
    description: "GoSkilling brings equality to your job search. It enables users to know their skills better and recruiters to find the best candidates through skill-scoring-matching."
  },
  {
    id: 8,
    name: "Heart for Cameroon",
    handle: "heartforcameroon",
    imageUrl: "https://bluecrunch.github.io/images/square0.jpg",
    date: "2017 - 2021",
    url: "heartforcameroon.org",
    category: "Full-Stack Developer",
    description: "Helping orphans in the streets of Douala, Cameroon and giving them help, hope, and a home. This is the idea behind Heart for Cameroon. I was responsible for the representation on the internet."
  },
  {
    id: 7,
    name: "Voxnap",
    handle: "voxnap",
    imageUrl: "https://bluecrunch.github.io/images/square0.jpg",
    date: "2020",
    category: "Product Management",
    description: "Recording a podcast is time intensive. Amd not everybody has a voice that sounds good for a listener. But there are many who are writing good content. Voxnap was the plan to give everybody the power to create a podcast with modern technology, or to create audio books for a fraction of the usual costs."
  },
  {
    id: 6,
    name: "HoaLoa",
    handle: "hoaloa",
    imageUrl: "https://bluecrunch.github.io/images/square0.jpg",
    date: "2020",
    category: "Product Management",
    description: "All your streaming services and social media in one app. This was the idea behind HoaLoa, a Hulu-inspired software with the goal to personalize entertainment so that watching is more enjoyable than TV ever was."
  },
  {
    id: 5,
    name: "DeepFriend",
    handle: "deepfriend",
    imageUrl: "https://bluecrunch.github.io/images/square0.jpg",
    date: "2020",
    category: "Product Management",
    description: "A universal app for everything. Not just chatting, not just sharing photos, but also shopping, booking, and everything you do on many different apps."
  },
  {
    id: 4,
    name: "214 Square",
    handle: "twofourteen",
    imageUrl: "https://bluecrunch.github.io/images/square0.jpg",
    date: "2016",
    category: "Product Management, Back-End Developer",
    description: "The crowd can help you to dress up better in a way that you like it. 214 Square was planned as a social fashion app with the possibility to tag goods in town and buy it there."
  },
  {
    id: 3,
    name: "Vinidra/Orbity",
    handle: "vinidra",
    imageUrl: "https://bluecrunch.github.io/images/square0.jpg",
    date: "2014",
    category: "Product Management, Full-Stack Developer",
    description: "What if trustworthiness in a social network does not come from the number of contents you have, but from who trusts and recommends you? Vinidra wanted to make business networking safer."
  },
  {
    id: 2,
    name: "The Franklin Journal",
    handle: "franklinjournal",
    imageUrl: "https://bluecrunch.github.io/images/square0.jpg",
    date: "2013",
    category: "Product Management",
    description: "Helping to overcome partisanship with a better understanding for arguments and opinions on the other end of the aisle was essential for The Franklin Journal."
  },
  {
    id: 1,
    name: "Red Moose",
    handle: "redmoose",
    imageUrl: "https://bluecrunch.github.io/images/square0.jpg",
    date: "2012 - 2013",
    category: "Product Management",
    description: "My very first project wanted to make wagering money on sports safer for the general public and tried to do so with techniques from financial markets."
  },
  ],
  {
    opininio:
    {
      name: 'Opininio',
      handle: 'opininio',
      imageUrl: '',
      date: "2023 - present",
      url: "opininio.com",
      category: "Product Manager, Full-Stack Developer",
      description: 'You like Nike and Adidas. But if you were ask to pick your favorite brand, which one would you pick? Opininio wants to answer which brands have the strongest appeal on consumers.',
      html: [
        '<p class="mt-5 mb-3">Information coming soon. Stay tuned. </p>',

      
      ]
    },
    goskilling:
    {
      name: 'GoSkilling',
      handle: 'goskilling',
      imageUrl: '',
      date: "2017 - 2021",
      url: "goskilling.com",
      category: "Product Manager, Full-Stack Developer",
      description: 'You can compare the performances of cars, computers, stocks, even athlete. What about candidates in a recruiting process? Peer-assessments allow job seekers to find out how good they are their job and how they compare to their co-workers. Recruiters can find candidates based on their skill scorings.',
      html: [
        '<p class="mt-5 mb-3">When I had my last day of my bachelor\'s program I got the idea for an algorithm to determine how qualified an employee or job candidate is. The background is that, though my friends in the tech industry told me that I am qualified enough to work as a product manager, I didn\'t get a product manager job. Frankly spoken, I didn\'t do well in interviews which I would describe as terrible formats to determine the quality of a candidate, same with tests. So, how would it be possible to determine the strength of a candidate and skip one or two intereviews or tests? </p>',
        '<p class="mt-5 mb-3">As a baseball fan who I am I thought of sabermetrics and that it is possible to easily analyze players and to compare them. Why not job seekers? Sure, there was HackerRank. But I knew well that not everybody likes test environments or questions in interviews - like me. So, what else could be done? And I reminded myself that companies used assessments and researchers relied on peer-to-peer reviews. Why don\'t just save time as a job seeker and get an evaluation by asking your pers from work, university?</p>',
        '<iframe class="w-full aspect-video" src="https://www.youtube.com/embed/J5OMvlnbwfA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>',
        '<iframe class="w-full aspect-video" src="https://www.youtube.com/embed/c30jIJ46Mlg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>',

        '<p class="mt-5 mb-3">My algorithm took some inputs from peers who\'d review me and everything would be crunched until we get a result. It\'s a bit like a neural network, but not exactly. In the end, the output is a rating that is based on mutual experience, peer skills and more. The principle was that those people who I have worked with now me well enough. And only this matters. No demographics were part of the inputs because age, ethnicity, gender, or anything else were irrelevant.</p>',
        '<p class="mt-5">I used first conversations with recruiters - and I been approached often for jobs as front-end oder back-end developers - to tell them what I currently do and to get their feedback, because they\'d be the users of this product. The feedback was promising. Recrutiers missed that they couldn\'t really determine how good a candidate really is and that is sometimes blind picking. So, I kept working, first in private with my slowly improving coding skills i nthe front-end. The first version didn\'t look good. But it included all basic functions I needed.</p>',
        '<p class="mt-5">After some time of prototyping myself I talked to my friend Akshay and we discussed doing GoSkilling as a mobile web. It was a good approach, but something was missing. Today I would say we should have continued, because for our MVP we could have done some specific features later.</p>',
        '<iframe class="w-full aspect-video" src="https://www.youtube.com/embed/TQK1nJlBjm4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>',

        '<p class="mt-5">With this path in mind - everything should be ready and look good - I recognized that I would have to improve my skills further and further. Over the time I meet and worked together with Edgar, a nice guy who once presented his employer\'s startup service in an entrepreneurship class. We two attended an incubation program by HHL Leipzig, a German business school. I admit that the time was not ideal due to private circumstances for both of us and also different views on where to start. And I had and have a focus on Northern America rather than Germany. Also, the incubator repeated what i have already learned at Journalism Lab, Startup School by Y-Combinator.</p>',
        '/images/goskilling/skilling0.jpg',
        '<p class="mt-5">Meanhwile I have overworked GoSkilling as I have taught myself NodeJS and ReactJS. The back-end was not a problem, but the front-end took too long due to my own high expectations I couldn\'t meet. GoSkilling became a part of my graduate capstone at Quantic. Together with my teammates Roxana, Julien, Perez, and Mohammed, we have further improved the business model, marketing, and strategy behind GoSkilling. Our product was reviewed and found for good. One of our professors called it promising.</p>',
        '/images/goskilling/goskilling_dashboard.png',
        '<p class="mt-5">Since January I am working with Oluwaseyi, a Nigerian front-end developer, on the platform to focus more on product management and business again with Perez.</p>',
        '/images/goskilling/goskilling_project.jpeg',
      
      ]
    },
    // \
    heartforcameroon:
    {
      name: 'Heart for Cameroon',
      status: "Project",
      handle: 'heartforcameroon',
      date: "2017 - 2021",
      url: "heartforcameroon.org",
      category: "Full-Stack Developer",
      description: 'As co-founder and IT coordinator of Heart for Cameroon in Germany I was responsible for building and maintaing the website. I took care of the domain, the host, and which CMS and/or language we use..',
      html: [
        '/images/hfc/hfc_start.png',
        '<p class="mt-5 mb-3">At Heart for Cameroon, our goal was to provide aid to the poorest children in the streets of Douala, Cameroon, a central African country. To maintain a good public image a website was necessary to inform our audience and members about the ongoing campaigns and upcoming events..</p>',
        '<p class="mt-5 mb-3">The first website used WordPress for a long time. It was hosted on a HostGator space and later moved to Dreamhost. To keep costs low now premium themes, plugins were used for Wordpress. This made it a bit challenging and time consuming, but not impossible. For a website, it was fine.</p>',
        '<p class="mt-5 mb-3">The challenge was to explain to the members of the organization how to use the CMS (how to create a post, how to upload an image) and to stay up to date with the technology, but also the content. With the organization being present in Cameroon and in Germany we wanted to address those who either speak English, French, or German.</p>',
        '/images/hfc/hfc_info.png',
        '<p class="mt-5 mb-3">Using Polly to offer a multi-lingual website offered the opportunity to operate the page in three different language. Unfortunatley, some page elements weren not translated into French due to some users forgetting to complete their tasks.</p>',
        '<p class="mt-5 mb-3">Over the time the website lacked stability due to changes in the Wordpress software and the themes. And new challenges were calling. When I decided to leave the organization, I wanted to leave with a nice gift: an easy to manage website.</p>',
        '/images/hfc/hfc_board.png',
        '<p class="mt-5 mb-3">I began to create a website for the organization that relied on ReactJS and TailwindCSS. The content, texts for the website and basic articles and images, were fetched via an API from Airtable which was relatively easy to manage. To make blogging easier and to spare the organization from paying for file hosting, a publication has been created on Medium and the articles were made available on the website.</p>',
        '<p class="mt-5 mb-3">The page was hosted on DigitalOcean. Unfortunately, the members decided to hire a marketing agency to create a new website shortly after I resigned from my position.</p>',
        '/images/hfc/hfc_news.png',
        '/images/hfc/hfc_initiatives.png',
      ]
    },
    voxnap:
    {
      name: 'Voxnap',
      handle: 'voxnap',
      imageUrl: '',
      date: "2020",
      category: "Product Manager/Ideator, Python Programmer",
      description: 'Recording a podcast is time intensive. Amd not everybody has a voice that sounds good for a listener. But there are many who are writing good content. Voxnap was the plan to give everybody the power to create a podcast with modern technology, or to create audio books for a fraction of the usual costs.',
      html: [
        '<p class="mt-5">In Summer 2020, after completing my Draper University class, my friend Muhammed Ali approached me with the news that JournalismusLab, a part of the media bureau of the state of North-Rhine Westphalia in Germany, is having kind of a competition to its incubation program. We, then totally three guys, participated in the program with the idea of a tool to automatically create podcasts from text. Then I was - and still am - a listener of podcasts.</p>',
        '<p class="mt-5">Then I was reading about text-to-speech services from Amazon Polly and Microsoft\'s Azure. This was fascinating and as somebody who doubted to have a good voice for a podcast it was a way to turn articles into a podcast. Or book into an audio book. And before I already thought of a way to use stories which Snapchat introduced then as a way to create media services. It was then called NMC, News Media Company, and would have had <a target="_blank" href="https://youtube.com/shorts/5NEluATCtvc?feature=share">moving visuals with voice-over</a>. I also had a first conversation and tests with two developers in Texas. But the life of a student can be requiring and everything stopped.</p>',
        '/images/voxnap/voxnap.png',
        '<p class="mt-5">In the pre-incubator program we got different tasks, like working out a value proposition canvas for our product which we called Voxnap - a vox (the voice) with a snap. As a business model we considered a pay-as-you-go model and a simple MVP to start with. Our MVP, in case that we would realize it, would have included a user area with text editor, the connection to AWS and a page for the audio output. Due to my then limited skills we tried to get help from a developer. Unfortunately, we didn\'t find any to help with prototyping.</p>',
        '<p class="mt-5">To get a better idea of the look and feel of the product we were working on I created a mockup with SketchApp. It allowed the user to click on different parts of a screen and to switch to other screens. All as usual. </p>',
        '<p class="mt-5">Unfortunately, we haven\'t been selected for the next round. Artifically generated voices wouldn\'t sound naturally enough for listeners. Like one in the team they probably thought of us addressing the German market. But I was thinking about the English-speaking market. </p>',
        '<p class="mt-5">After that I was still interested in the idea of software that would create podcasts from text - or video from text. I began to experimenting with some libraries for Python and built a tool that would turn text into audio and grab video, images from some public sources and create a video. This, of course, wasn\'t perfect, but it was a nice output for a month of work.</p>',
        '<iframe class="w-full aspect-video"  src="https://www.youtube.com/embed/zqwKjYC1Vnc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>',

      ]
    },
    hoaloa:
    {
      name: 'HoaLoa',
      handle: 'hoaloa',
      imageUrl: '',
      date: "2016",
      category: "Product Manager/Ideator",
      description: 'All your streaming services and social media in one app. This was the idea behind HoaLoa, a Hulu-inspired software with the goal to personalize entertainment so that watching is more enjoyable than TV ever was.',
      html: [
        '<p class="mt-5">HoaLoa was the concept for a streaming aggregator with social features. Before I knew that e.g. Apple TV shows suggestions for content from other video apps, I was thinking of an app that was a bit like that. </p>',
        '<p class="mt-5">I like movies and good TV shows. But these shows are distributed over many apps and sometimes I just want to watch like in the past when sitting in front of a TV. And when watching the news I would like to watch the news rather than a boring interview from a soccer team coach.</p>',
        '<p class="mt-5">Personalized news, optionally customized linear TV, and including content from your friends on social networks, this was HoaLoa. And then I already was thinking about watch parties what you know today from, e,g. Amazon Prime Video.</p>',
        '/images/hoaloa/hoaloa_accounts.png',
        '/images/hoaloa/hoaloa_desktop.png',
        '/images/hoaloa/hoaloa_lineup.png',
        '/images/hoaloa/hoaloa_selectnext.png',
        '/images/hoaloa/hoaloa_friendwatch.png',

        '/images/hoaloa/hoaloa_perslineup.png',
        '/images/hoaloa/hoaloa_persnews.png',
        '/images/hoaloa/hoaloa_persnews2.png',

        '/images/hoaloa/hoaloa_social1.png',
        '/images/hoaloa/hoaloa_social2.png',
      ]
    },
    deepfriend:
    {
      name: 'DeepFriend',
      handle: 'deepfriend',
      imageUrl: '',
      date: "2016",
      category: "Product Manager/Ideator",
      description: 'A universal app for everything. Not just chatting, not just sharing photos, but also shopping, booking, and everything you do on many different apps.',
      html: [
        '<p class="mt-5">An app for everything. That was the idea behind DeepFriend (maybe not the best name, I know), a concept for a universal app inspired by Chinese WeChat. The idea was that DeepFriend (DF) would work like a social network with separations between private and business networking (inspired by Google+ circles.  Additionally, you would have been able to share content and, here comes WeChat, work with integrated apps, e.g. for Kayak, to book a hotel room. It should have been the app you open first when you unlock your screen.</p>',
        '/images/df/df.jpg',
        '/images/df/deepfriend.png',
        '/images/df/dfartboards.png',
      ]
    },
    twofourteen:
    {
      name: '214 Square',
      handle: '214',
      imageUrl: '',
      date: "2016 - 2017",
      category: "Product Manager, Back-End Developer",
      description: 'The crowd can help you to dress up better in a way that you like it. 214 Square was planned as a social fashion app with the possibility to tag goods in town and buy it there.',
      html: [
        '<p class="mt-5">Problem</p>',
        '<p class="mt-5">One of the problems we face when we get up is what we will wear. With my personal fashion style being chaotic while being able to give friends an advice on how they should dress, I thought, why not asking pals for their advise when I show them a photo of mine and explain them what I am looking for. Further, I began running survey among students and asked them about their shopping behavior and more. The questions also included a first mockup of the app. The focus laid on mobile. Responses from the surveys were good.</p>',
        '<iframe class="w-full aspect-video" src="https://www.youtube.com/embed/4N5yTVPnPeM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>',

        '<p class="mt-5">Solution</p>',
        '<p class="mt-5">I further worked out the product with sketches, market research, personas, and more. With the help from my friend Samy I was able to fund the development of a first prototype for iOS while working on the PHP-based back-end. We planned to allow users to not just share their questions, but also to reply with images.  It would have worked as follows:</p>',
        '<p class="mt-5">A user who we call Cindy from San Francisco logs into 214 Square with a question plus photo. The post is viewable for every user in a radius of 15 miles. Now, Gretchen reads the post and just spotted something she things Cindy can wear. She takes a photo, uploads it with some tags appended and marks the store. Other users can review the advise by either up- or downvoting the post. In the end, Cindy decides on the advice and can buy the items recommended in the marked stores.</p>',
        '<p class="mt-5">Cindy can also scroll through items tagged with specific tags and add it to her "collection", a personal list of clothes she finds admirable.</p>',
        '<p class="mt-5">Corporate customers would have been able to use the data from votes, collections, and more to analyze developing trends and demand. Further, we hoped to make it possible to even better understand local fashion tastes.</p>',
        '<p class="mt-5">Outcome</p>',
        '<iframe class="w-full aspect-video" src="https://www.youtube.com/embed/X-gofIbEqag" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>',

        '<p class="mt-5">With a young audience we were interesting for brands and were in talks with a local business man who ran several companies, among others a marketing agency. Unfortunately the gentleman ran into trobuel and we were unable to raise money to proceed with the the development of 214 Square. </p>',
        '<p class="mt-5">Square</p>',
        '<iframe width="339" height="603" src="https://www.youtube.com/embed/sXoXH4lL2Oo" title="214 Square Prototype" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>',

      ]
    },
    vinidra:
    {
      name: 'Vinidra/Orbity',
      handle: 'vinidra',
      imageUrl: '',
      date: "2013 - 2015",
      category: "Product Manager",
      description: 'What if trustworthiness in a social network does not come from the number of contents you have, but from who trusts and recommends you? Vinidra wanted to make business networking safer.',
      html: [
        '<p class="mt-5">Vinidra was an intranet service for companies, projects, and classes in a social network. It aimed to make followers obsolete as a number to see how valuable a contact is due to a person I met who created over 20 fake accounts just to look serious. I have worked on an algorithm to determine your trustworthiness through who is connected with you or who is recommending you. The higher the recommender\'s reputation, the more it affects you.</p>',
        '<p class="mt-5">Vinidra was used by a class at Bielefeld University. But we didn\'t gain traction. Too few joined and used it. But the approach was good I got told from a partner of a large VC in the Bay Area and so I kept working on it, then without Marek.</p>',
        '/images/vinidra/v0.jpg',
        '<p class="mt-5">Vinidra became Orbity and I gathered a team of developers around me. They came from all over the world, e.g. the United States, Brazil, Morocco, Hungary, and India. Working with a team in so many different time zones made things more complicated then. And I just gained more experience as a product manager working with a larger team.  And this is why Orbity failed despite tackling problems of trust on business networks then and despite having the chance to offering an alternative to LinkedIn. </p>',
      ]
    },
    franklinjournal:
    {
      name: 'The Franklin Journal',
      handle: 'franklinjournal',
      imageUrl: '',
      date: "2013",
      category: "Product Manager",
      description: 'Helping to overcome partisanship with a better understanding for arguments and opinions on the other end of the aisle was essential for The Franklin Journal.',
      html: [
        '<p class="mt-5 ">We, Marek and I, wanted to build a page that would allow everybody to write an article from their point of view so that you could learn to understand different opinions. Inspired by the tension between Republicans and Democrats in congress (we are talking about 2012/13), we hoped to bring people closer together. Rather than judging each other for their views, we wanted to show you the opposite view and argumentation.</p>',
        '/images/fj/fjc0.png',
        '<p class="mt-5">With an article editor and some settings to set your political stand (left or right authoritarian or libertarian) you could create an article and share with the people using the platform. Unfortunately, we overestimated how many would write articles and so the most articles were mine. </p>',
        '<p class="mt-5">But we had a huge boost when Gezi protests erupted in Turkey 2013. Without spending a penny on Marketing, we had 3,000 unique visitors in a month. But still no writers, just readers. Even the integration of micro-blogging didn\'t help. We speculated to offer an alternative to Twitter if the service would have been blocked then in Turkey.</p>',
        '<p class="mt-5">After FJ failed, I still played around a bit, creating mock-ups for a modern design.</p>',
        '/images/fj/fjc1.png',
        '/images/fj/fjc2.png',
      ]
    },
    redmoose:
    {
      name: 'Red Moose',
      handle: 'redmoose',
      imageUrl: '',
      date: "2012 - 2013",
      category: "Product Manager/Ideator",
      description: 'My very first project wanted to make wagering money on sports safer for the general public and tried to do so with techniques from financial markets.',
      html: [
        '<p class="mt-5">Red Moose was my first project. As a soccer fan then I saw many people betting on the outcome of matches. Too risky, I thought, and considered another solution: sports trading. Rather than betting on a single match, users would have been able to trade virtual stocks representing sports clubs like Feyenoord Rotterdam or Liverpool FC. The stocks would compete with other stocks on leage-based indices and therefore not lose money at once like knock-out financial products. Rather, our traders would have got the chance to turn everything into a profit if they understood trends in sports.</p>',
        '<p class="mt-5">The name Red Moose came from playing with stock symbols where the bull and green stand for rising stocks while a bear and red represent decreasing prices. We made red and the moose the symbols for rising prices and a shark and blue the symbols for falling prices. We wanted to become a brand that would represent a higher lifestyle rather than a cheap corner bar where people in Germany watched soccer.</p>',
        '/images/redmoose/rm0.jpg',
        '<p class="mt-5">The challenge was to build a back-end that determines the right stock price based on ask and bid. This was new and got more complicated with including limit and market order types. Even more complicated: was it gambling or a game of skill? I had to do research on the laws in Germany and the United States because we saw fantasy sports as a prdocut that was somehow similar to Red Moose.  While German laws were not really clear, I read the Unlawful Internet Gambling Enforcement Act (UIGEA) and was able to tell a former Goldman Sachs partner who had a similar approach that his product is in violation with US federal law. The company changed the product. And we still hoped for clarification on laws in Germany. We also considered to start in the Republic of Ireland where laws were clear.</p>',
        '<p class="mt-5">My partner Marek has been working on the front- and back-end of the product meanwhile and also managed the database. He was the second programmer after first working with a genteman living in Ireland. Unfortunately, he used to work with Ruby on Rails 3 and this made hosting very ocmplicated. With Marek we used PHP and it worked well except from the cronjobs we used to execute the trading algorithm every 15 minutes. Our host wasn\'t happy. </p>',
        '<p class="mt-5">While workong on Red Moose we tried to raise money. For a first-time founder it wasn\'t a bad experience. We have got good responses from the founder of a Zynga competitor, also from a German business veteran, and been in touch with the c-level of a then well-respected company in Silicon Valley. Latter one only for a short time, but we got responses. The doubts emerged due to the unknown legal status of our product. I personally approached the founder center of Bielefeld University, but the response was that "no adult would use that." With the unclear situation in mind we also rejected an angel investment offer and gave the product up as we coudln\'t afford a lawyer to give us advice. </p>',
        '/images/redmoose/rm1.jpg',
        '/images/redmoose/rm2.jpg',
      ]
    },
  }]

const arr = [0, 1, 2, 3];
function validURL(str) {
  var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return !!pattern.test(str);
}
export function ProjectFrame() {
  return <>
    <Outlet />
  </>
}

export function Projects() {
  return <Centrify>
    {projects[0].map((project) => (
      <article key={project.id} className="flex max-w-xl flex-col items-start justify-between mb-10 p-5 hover:bg-gray-50">
        <div className="flex items-center gap-x-4 text-xs">
          <a
            href={project.category}
            className="relative z-10 rounded-full bg-gray-200 py-1.5 px-3 font-medium text-gray-600 hover:bg-gray-100"
          >
            {project.category}
          </a>
        </div>
        <div className="group relative">
          <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
            <Link to={project.handle}>
              <span className="absolute inset-0" />
              {project.name}
            </Link>
          </h3>
          <div className="flex items-center gap-x-4 text-xs">
          <time dateTime={project.datetime} className="text-gray-500">
            {project.date}
          </time>
          </div>
          <p className="mt-5 text-sm leading-6 text-gray-600 line-clamp-3">{project.description}</p>
        </div>

      </article>
    ))}

  </Centrify>
}

export function Project() {
  let { projecthandle } = useParams();
  return <div className="bg-black text-gray-100 py-32 px-6 lg:px-8">
    <div className="mx-auto max-w-3xl text-base leading-7 text-gray-200">
      <p className="text-base font-semibold leading-7 text-orange-600">{projects[1][projecthandle].status}</p>
      <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-200 sm:text-4xl">{projects[1][projecthandle].name}</h1>
      <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-200 sm:text-4xl"></h1>
      {
        projects[1][projecthandle].category ? projects[1][projecthandle].category : ""
      }
      {
        projects[1][projecthandle].date ? " | " 
        : projects[1][projecthandle].url ? " | "
        : ""
      }
      {
          projects[1][projecthandle].date ? projects[1][projecthandle].date  : "" 
      }
      {
         projects[1][projecthandle].url ? " | " : ""
      }
      {
            projects[1][projecthandle].url ? projects[1][projecthandle].url : ""
      }

      <p className="mt-6 text-xl leading-8">
        {projects[1][projecthandle].description}
      </p>

      {projects[1][projecthandle].html.map((x) => (
        !x.startsWith('<')
          ? <img className="my-10" src={x} />
          : <div dangerouslySetInnerHTML={{ __html: x }}></div>
      ))}
    </div>
  </div>
}